import React, { useState, useEffect } from 'react';
import { Github, Linkedin, Mail, MapPin, Terminal, Globe, Award, Code, Database } from 'lucide-react';

const TypewriterText = ({ text = "", delay = 0, className = "" }) => {
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const safeText = text ?? ""; // Ensure text is never undefined

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTyping(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  useEffect(() => {
    if (!isTyping) return;

    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= safeText.length) {
        setDisplayText(safeText.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [safeText, isTyping]);

  return (
    <span className={`${className} ${isTyping ? 'after:content-["_"] after:animate-blink after:text-cyan-400' : ''}`}>
      {displayText}
    </span>
  );
};

const Section = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <div className={`transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      {children}
    </div>
  );
};

const ResumeWebsite = () => {
  const [showBootSequence, setShowBootSequence] = useState(true);

  useEffect(() => {
    // Hide boot sequence after 4 seconds
    const timeout = setTimeout(() => {
      setShowBootSequence(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  // Your data objects here...
  const personalInfo = {
    name: "Parth Choksi",
    title: "Technology Enthusiast",
    location: "Raleigh, NC",
    email: "info@parthkchoksi.com",
    linkedin: "linkedin.com/in/parthkchoksi",
  };

  const experience = [
    {
      company: "Trellix",
      position: "Senior Cloud Engineer",
      period: "January 2023 - Present",
      achievements: [
        "Implemented vulnerability remediation on cloud instances to meet FEDRAMP requirements.",
        "Using Ansible and AWS Systems manager, implemented account wide security measures.",
        "Containerized Teamcity using helm and argued for cost cutting measures.",
        "Imported AWS resources into terraform to enable Infrastructure as code practices.",
        "Setup code build and code deploy pipeline in AWS to deploy packages across multiple accounts.",
        "Setup Grafana charts to have a better understanding of system usage via node exporter."
      ]
    },
    {
      company: "Bitsight Technologies",
      position: "DevOps Engineer",
      period: "December 2019 - January 2023",
      achievements: [
        "Setup micro services using helm to be deployed on kubernetes.",
        "Setup infrastructure policies to be implemented account wide.",
        "Created python scripts for various tasks.",
        "Created terraform code for AWS resources.",
        "Migrated ec2 instances into terraform and created ansible roles to modernize infrastructure."
      ]
    },
    {
      company: "Cisco Systems",
      position: "Software Development Engineer - Cloud Security",
      period: "July 2018 - December 2019",
      achievements: [
        "Part of the Cloud Security team responsible for providing various security modules.",
        "Developed and Released the Ansible role responsible for securing Apache Tomcat.",
        "Contributed towards securing various platforms including operating systems such as Redhat, Ubuntu, Debian as well as Docker, Packer etc.",
        "Built a Cloud metrics collector service to track usage and setup visualizations via Amazon Quicksight."
      ]
    },
    {
      company: "Fidelity Investments",
      position: "Associate Software Engineer",
      period: "January 2017 - June 2018",
      achievements: [
        "Worked as a Developer lead for an API built on the cloud using AWS services.",
        "Utilized various AWS services including SQS, EC2, SNS, Lambda, KMS and ALB.",
        "Tech lead on a POC API built using python on a server less implementation with API Gateway.",
        "Built an internal visualization application completely hosted on cloud utilizing AWS EC2, S3, SQS, Lambda and Dynamo db.",
        "Built a node proxy server to demonstrate an in house POC simulating DDOS attacks.",
        "Analyzed vendor solution such as Immune.io."
      ]
    },
    {
      company: "TCUBE Solutions",
      position: "Software Engineering Intern",
      period: "August 2015 - December 2016",
      achievements: [
        "Using XML Api provided by NetSuite Open Air, set up python scripts to speed up the process of data collection and data processing.",
        "Set up TCube’s internal database on MSSQL and programmed data queries for enhanced data visualization with Microsoft PowerBI.",
        "Migrated Reinsurance system across servers and assisted in setting up on Duckcreek instance on TCube servers.",
        "Participated in code reviews and testing.",
        "Learned best practices in software development."
      ]
    }
  ];

  const technicalSkills = {
    "Backend": ["Node.js", "Python", "Java", "PostgreSQL", "Redis", "Elasticsearch", "DynamoDB" ],
    "DevOps": ["AWS", "Docker", "Kubernetes", "CI/CD", "Terraform", "Ansible", "Jenkins", "GitLab", "Helm", "Packer", "VMWare"],
    "Tools": ["Git", "Jira", "VS Code", "DataDog", "Prometheus", "Grafana"]
  };

  const languages = [
    { name: "English", level: "Professonal" },
    { name: "Gujarati", level: "Professional" }
  ];

  const certifications = [
    {
      name: "AWS Solutions Architect Associate",
      issuer: "Amazon Web Services",
      date: "2021",
      credentialId: "MSWRETBBC14116CC"
    }
  ];

  const education = {
    degree: "B.S. Computer Science",
    school: "University of South Carolina",
    year: "December - 2016"
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-300 font-mono">
      {/* Boot sequence overlay */}
      {showBootSequence && (
        <div className="fixed inset-0 bg-gray-900 z-50 flex items-center justify-center">
          <div className="text-cyan-400 space-y-2">
            <TypewriterText text="Initializing resume.sys..." delay={0} />
            <TypewriterText text="Loading modules..." delay={1000} />
            <TypewriterText text="Starting personal.profile..." delay={2000} />
            <TypewriterText text="System ready." delay={3000} />
          </div>
        </div>
      )}

      {/* Main content */}
      <div className={`transition-opacity duration-500 ${showBootSequence ? 'opacity-0' : 'opacity-100'}`}>
        {/* Header/Hero Section */}
        <header className="border-b border-cyan-800">
          <div className="max-w-5xl mx-auto px-6 py-12">
            <div className="flex items-start justify-between">
              <div>
                <TypewriterText 
                  text={personalInfo.name}
                  delay={4200}
                  className="text-4xl font-bold text-cyan-400 block mb-2"
                />
                <div className="inline-flex items-center gap-2 bg-gray-800 px-3 py-1 rounded border border-cyan-800">
                  <Terminal size={16} className="text-cyan-400" />
                  <span className="text-cyan-400">&gt;</span>
                  <TypewriterText 
                    text={personalInfo.title}
                    delay={4500}
                    className="text-gray-300"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 text-sm">
                <div className="flex items-center gap-2">
                  <MapPin size={16} className="text-cyan-400" />
                  <TypewriterText text={personalInfo.location} delay={4700} />
                </div>
                <a href={`mailto:${personalInfo.email}`} className="flex items-center gap-2 hover:text-cyan-400 transition-colors">
                  <Mail size={16} />
                  <TypewriterText text={personalInfo.email} delay={4900} />
                </a>
                <a href={`https://${personalInfo.linkedin}`} className="flex items-center gap-2 hover:text-cyan-400 transition-colors">
                  <Linkedin size={16} />
                  <TypewriterText text={personalInfo.linkedin} delay={5300} />
                </a>
              </div>
            </div>
          </div>
        </header>

        <main className="max-w-5xl mx-auto px-6 py-12">
          {/* Experience Section */}
          <Section delay={5500}>
            <section className="mb-16">
              <div className="flex items-center gap-3 mb-8">
                <Code size={24} className="text-cyan-400" />
                <h2 className="text-2xl font-bold text-cyan-400">Experience</h2>
              </div>
              <div className="space-y-8">
                {experience.map((job, index) => (
                  <div key={index} className="bg-gray-800 p-6 rounded border border-cyan-800">
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-bold text-cyan-400">{job.position}</h3>
                        <p className="text-gray-400">{job.company}</p>
                      </div>
                      <span className="text-gray-500 bg-gray-900 px-3 py-1 rounded-full text-sm">
                        {job.period}
                      </span>
                    </div>
                    <ul className="space-y-2">
                      {job.achievements.map((achievement, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <span className="text-cyan-400">&gt;</span>
                          <span>{achievement}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </section>
          </Section>

          {/* Technical Skills Section */}
          <Section delay={6000}>
            <section className="mb-16">
              <div className="flex items-center gap-3 mb-8">
                <Database size={24} className="text-cyan-400" />
                <h2 className="text-2xl font-bold text-cyan-400">Technical Skills</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {Object.entries(technicalSkills).map(([category, skills]) => (
                  <div key={category} className="bg-gray-800 p-6 rounded border border-cyan-800">
                    <h3 className="text-lg font-bold text-cyan-400 mb-4">{category}</h3>
                    <div className="flex flex-wrap gap-2">
                      {skills.map((skill, index) => (
                        <span 
                          key={index}
                          className="bg-gray-900 text-gray-300 px-3 py-1 rounded text-sm border border-cyan-900"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </Section>

          {/* Languages Section */}
          <Section delay={6500}>
            <section className="mb-16">
              <div className="flex items-center gap-3 mb-8">
                <Globe size={24} className="text-cyan-400" />
                <h2 className="text-2xl font-bold text-cyan-400">Languages</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {languages.map((language, index) => (
                  <div key={index} className="bg-gray-800 p-4 rounded border border-cyan-800">
                    <h3 className="text-lg font-bold text-cyan-400">{language.name}</h3>
                    <p className="text-gray-400 mt-1">{language.level}</p>
                  </div>
                ))}
              </div>
            </section>
          </Section>

          {/* Certifications Section */}
          <Section delay={7000}>
            <section className="mb-16">
              <div className="flex items-center gap-3 mb-8">
                <Award size={24} className="text-cyan-400" />
                <h2 className="text-2xl font-bold text-cyan-400">Certifications</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {certifications.map((cert, index) => (
                  <div key={index} className="bg-gray-800 p-6 rounded border border-cyan-800">
                    <h3 className="text-lg font-bold text-cyan-400">{cert.name}</h3>
                    <p className="text-gray-400 mt-1">{cert.issuer}</p>
                    <div className="flex justify-between mt-4 text-sm text-gray-500">
                      <span>$ chmod {cert.date}</span>
                      <span>ID: {cert.credentialId}</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </Section>

          {/* Education Section */}
          <Section delay={7500}>
            <section>
              <div className="flex items-center gap-3 mb-8">
                <Terminal size={24} className="text-cyan-400" />
                <h2 className="text-2xl font-bold text-cyan-400">Education</h2>
              </div>
              <div className="bg-gray-800 p-6 rounded border border-cyan-800">
                <h3 className="text-lg font-bold text-cyan-400">{education.degree}</h3>
                <p className="text-gray-400 mt-1">{education.school}</p>
                <p className="text-gray-500 mt-2">$ graduation -m -y {education.year}</p>
              </div>
            </section>
          </Section>
        </main>

        {/* Footer */}
        <footer className="border-t border-cyan-800 mt-16">
          <div className="max-w-5xl mx-auto px-6 py-8 text-center text-gray-500">
            <TypewriterText 
              text={`$ echo "© ${new Date().getFullYear()} ${personalInfo.name}. All rights reserved."`}
              delay={8000}
            />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ResumeWebsite;
